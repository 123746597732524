import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store';
import { projectFunctions } from '../firebase/config';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/wms',
    name: 'wms',
    component: () => import('../views/Wms.vue'),
    beforeEnter: async (to, from, next) => {
      store.state.system.loading = true;
      const role = await projectFunctions.httpsCallable('check_role')()
      if (Number(role.data) >= 1) {
        next();
        store.state.system.loading = false;
      } else {
        next({ name: 'Home' });
        store.state.system.loading = false;
      }
    },
    children: [
      {
        path: '/wms/unauthorized',
        name: 'Unauthorized',
        component: () => import('../views/Wms/Permissions/Unauthorized.vue'),
      },
      {
        path: '/wms/',
        name: 'WmsMain',
        component: () => import('../views/Wms/Main.vue'),
      },
      {
        path: '/wms/qrpallet/:pallet_id',
        name: 'qrpallet',
        props: true,
        component: () => import('../views/Wms/QrPallet.vue'),
      },
      {
        path: '/wms/settings',
        name: 'settings',
        component: () => import('../views/Wms/Settings.vue'),
      },
      {
        path: '/wms/stockcount',
        name: 'stockcount',
        component: () => import('../views/Wms/StockCount.vue'),
      },
      {
        path: '/wms/setlogistics',
        name: 'setlogistics',
        component: () => import('../views/Wms/setLogistics.vue'),
      },
      {
        path: '/wms/checklist',
        name: 'checklist',
        component: () => import('../views/Wms/Managment/CheckList.vue'),
      },
      {
        path: '/wms/fastview',
        name: 'fastview',
        component: () => import('../views/Wms/FastView.vue'),
      },
      {
        path: '/wms/suppliersin',
        name: 'suppliersin',
        component: () => import('../views/Wms/Suppliers/SupplierIn.vue'),
      },
      {
        path: '/wms/recntrans',
        name: 'recntrans',
        component: () => import('../views/Wms/RecAndTrans.vue'),
      },
      {
        path: '/wms/transfer/:dest',
        name: 'transfer',
        component: () => import('../views/Wms/Transfer.vue'),
      },
      {
        path: '/wms/reccontainer',
        name: 'reccontainer',
        component: () => import('../views/Wms/RecContainer.vue'),
      },
      {
        path: '/wms/reccontainerwpo',
        name: 'reccontainerwpo',
        component: () => import('../views/Wms/RecContainerWPO.vue'),
      },
      {
        path: '/wms/suppliersreturns',
        name: 'suppliersreturns',
        component: () => import('../views/Wms/Suppliers/SupplierReturns.vue'),
      },
      {
        path: '/wms/setSpec23',
        name: 'setSpec23',
        component: () => import('../views/Wms/setSpec23.vue'),
      },
      {
        path: '/wms/itemlogistics',
        name: 'itemlogistics',
        component: () => import('../views/Wms/Managment/ItemLogistics.vue'),
      },
      {
        path: '/wms/createmission',
        name: 'createmission',
        component: () => import('../views/Wms/Missions/CreateMission.vue'),
      },
      {
        path: '/wms/stockcount/pu/:uid',
        name: 'stockcountpu',
        props: true,
        component: () => import('../components/StockCount/CountPu.vue'),
      },
      {
        path: '/wms/stockcount/it/:uid',
        name: 'stockcountit',
        props: true,
        component: () => import('../components/StockCount/CountIt.vue'),
      },
      {
        path: '/wms/stockcount/tmp/:uid',
        name: 'stockcounttmp',
        props: true,
        component: () => import('../components/StockCount/CountTmp.vue'),
      },
      {
        path: '/wms/stockcount/stickers/:uid',
        name: 'stockcountstickers',
        props: true,
        component: () => import('../components/StockCount/CountStickers.vue'),
      },
      {
        path: '/wms/stockcount/returns/:uid',
        name: 'stockcountreturns',
        props: true,
        component: () => import('../components/StockCount/CountReturns.vue'),
      },
      {
        path: '/wms/live',
        name: 'live',
        props: true,
        component: () => import('../views/Wms/Live.vue'),
      },
      {
        path: '/wms/returns',
        name: 'returns',
        component: () => import('../views/Wms/Returns.vue'),
      },
      {
        path: '/wms/LocationsTable',
        name: 'LocationsTable',
        component: () => import('../views/Wms/LocationsTable.vue'),
      },
      {
        path: '/wms/LocationsTableInfo',
        name: 'LocationsTableInfo',
        component: () => import('../views/Wms/LocationsTableInfo.vue'),
      },
      {
        path: '/wms/likut',
        name: 'likut',
        component: () => import('../views/Wms/Melaket.vue'),
      },
      {
        path: '/wms/newpallet',
        name: 'newpallet',
        component: () => import('../views/Wms/Likut/NewPallet.vue'),
      },
      {
        path: '/wms/newpalletitur',
        name: 'newpalletitur',
        props: true,
        component: () => import('../views/Wms/Likut/NewPalletItur.vue'),
      },
      {
        path: '/wms/newtemppallet',
        name: 'newtemppallet',
        props: true,
        component: () => import('../views/Wms/Likut/NewTempPallet.vue'),
      },
      {
        path: '/wms/newpalletstickers',
        name: 'newpalletstickers',
        component: () => import('../views/Wms/Likut/NewPalletStickers.vue'),
      },
      {
        path: '/wms/locatepallet',
        name: 'locatepallet',
        component: () => import('../views/Wms/Likut/LocatePallet.vue'),
      },
      {
        path: '/wms/pickuploc',
        name: 'pickuploc',
        component: () => import('../views/Wms/Likut/PickUpLoc.vue'),
      },
      {
        path: '/wms/deletepallet',
        name: 'deletepallet',
        component: () => import('../views/Wms/Likut/DeletePallet.vue'),
      },
      {
        path: '/wms/newpalletpickup',
        name: 'newpalletpickup',
        component: () => import('../views/Wms/Likut/NewPalletPickUp.vue'),
      },
      {
        path: '/wms/rikun',
        name: 'rikun',
        component: () => import('../views/Wms/Likut/Rikun.vue'),
      },
      {
        path: '/wms/setboxcode',
        name: 'setboxcode',
        component: () => import('../views/Wms/Likut/SetBoxCode.vue'),
      },
      {
        path: '/wms/search',
        name: 'search',
        component: () => import('../views/Wms/Likut/Search.vue'),
      },
      {
        path: '/wms/printstation',
        name: 'printstation',
        component: () => import('../views/Wms/PrintStation.vue'),
      },
      {
        path: '/wms/Reports',
        name: 'Reports',
        component: () => import('../views/Wms/Reports/Reports.vue'),
      },
      {
        path: '/wms/stockreport',
        name: 'stockreport',
        component: () => import('../views/Wms/Reports/StockReport.vue'),
      },
      {
        path: '/wms/bidb',
        name: 'bidb',
        component: () => import('../views/Wms/Reports/StockReport/DBview.vue'),
      },
      {
        path: '/wms/dohmlay',
        name: 'dohmlay',
        component: () => import('../views/Wms/Reports/DohMlay/DohMlay.vue'),
        children: [
          {
            path: '',
            name: 'loadItems',
            component: () => import('../views/Wms/Reports/DohMlay/LoadItems.vue'),
          },
          {
            path: 'ItemsTable',
            name: 'dohMlaiTable',
            component: () => import('../views/Wms/Reports/DohMlay/ItemsTable.vue'),
          },
        ]
      },
      {
        path: '/wms/bi',
        name: 'bi',
        component: () => import('../views/Wms/Reports/StockReport/BiView.vue'),
        children: [
          {
            path: '/bi/assortments',
            name: 'biassortments',
            component: () => import('../views/Wms/Reports/StockReport/BiAssortments.vue'),
            beforeEnter: (to, from, next) => {
              // Check if the navigation is a direct entry to a child of /wms/bi
              if (from.path !== '/wms/bidb' && from.path !== '/wms/bi' && from.path !== '/bi/products' && from.path !== '/bi/pizur' && from.path !== '/bi/offers') {
                next({ path: '/wms/bidb' });
              } else {
                next();
              }
            },
          },
          {
            path: '/bi/products',
            name: 'biproducts',
            component: () => import('../views/Wms/Reports/StockReport/BiProducts.vue'),
            beforeEnter: (to, from, next) => {
              // Check if the navigation is a direct entry to a child of /wms/bi
              if (from.path !== '/wms/bidb' && from.path !== '/wms/bi' && from.path !== '/bi/assortments' && from.path !== '/bi/pizur') {
                next({ path: '/wms/bidb' });
              } else {
                next();
              }
            },
          },
          {
            path: '/bi/pizur',
            name: 'bipizur',
            component: () => import('../views/Wms/Reports/StockReport/BiPizur.vue'),
            beforeEnter: (to, from, next) => {
              // Check if the navigation is a direct entry to a child of /wms/bi
              if (from.path !== '/wms/bidb' && from.path !== '/wms/bi' && from.path !== '/bi/assortments' && from.path !== '/bi/products') {
                next({ path: '/wms/bidb' });
              } else {
                next();
              }
            },
          },
          {
            path: '/bi/offers',
            name: 'offers',
            component: () => import('../views/Wms/Reports/StockReport/Offers.vue'),
            beforeEnter: (to, from, next) => {
              // Check if the navigation is a direct entry to a child of /wms/bi
              if (from.path !== '/wms/bidb' && from.path !== '/wms/bi' && from.path !== '/bi/assortments' && from.path !== '/bi/products') {
                next({ path: '/wms/bidb' });
              } else {
                next();
              }
            },
          },
          {
            path: '/bi/binewoffer',
            name: 'binewoffer',
            component: () => import('../views/Wms/Reports/StockReport/BiNewOffer.vue'),
          },
        ]
      },
      {
        path: '/wms/orderreport',
        name: 'orderreport',
        component: () => import('../views/Wms/Reports/OrderReport.vue'),
      },
      {
        path: '/wms/stockcountreport',
        name: 'stockcountreport',
        component: () => import('../views/Wms/Reports/StockCountReport.vue'),
      },
      {
        path: '/wms/stickerreport',
        name: 'stickerreport',
        component: () => import('../views/Wms/Reports/StickerReport.vue'),
      },
      {
        path: '/wms/archive-reports',
        name: 'ArchiveReport',
        component: () => import('../views/Wms/Reports/ArchiveReport.vue'),
      },
      {
        path: '/wms/collecting',
        name: 'Collecting',
        component: () => import('../views/Wms/Collecting/Collecting.vue'),
      },
      {
        path: '/wms/work-schedule',
        name: 'WorkSchedule',
        component: () => import('../views/Wms/Collecting/WorkSchedule.vue'),
      },
      {
        path: '/wms/collecting-page',
        name: 'CollectingPage',
        component: () => import('../views/Wms/Collecting/CollectingPage.vue'),
      },
      {
        path: '/wms/collecting-display-page',
        name: 'CollectingDisplay',
        component: () => import('../views/Wms/Collecting/CollectingDisplay.vue'),
      },
      {
        path: '/wms/check-orders',
        name: 'CheckOrders',
        component: () => import('../views/Wms/Collecting/CheckOrders.vue'),
      },
      {
        path: '/wms/collection-report',
        name: 'CollectionReport',
        component: () => import('../views/Wms/Collecting/CollectionReport.vue'),
      },
      {
        path: '/wms/pu-report',
        name: 'PuReport',
        component: () => import('../views/Wms/Collecting/PuReport.vue'),
      },
      {
        path: '/wms/permissions',
        name: 'Permissions',
        component: () => import('../views/Wms/Permissions/Permissions.vue'),
      },
      {
        path: '/wms/melaketorders',
        name: 'melaketorders',
        component: () => import('../views/Wms/Melaket_orders.vue'),
      },
      {
        path: '/wms/users',
        name: 'users',
        component: () => import('../views/Wms/Users.vue'),
      },
    ]
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('../views/Test.vue'),
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// Trigger Service Worker update on route change
router.afterEach(() => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistration().then((registration) => {
      if (registration) {
        console.log('Checking for service worker updates after route change...');
        registration.update();
      }
    });
  }
});

router.beforeEach((to, from, next) => {
  console.log("Navigating to:", to.fullPath);

  // If 'to.path' is in allGuardedRoutes,
  // we must check if the user has permission for it
  if (Array.isArray(store.state.allGuardedRoutes) && store.state.allGuardedRoutes.includes(to.path)) {
    if (!Array.isArray(store.state.user.permissions) || !store.state.user.permissions.includes(to.path)) {
      console.warn("Access denied: user does not have permission for this route.");
      // return next("/wms/unauthorized");
    }
  }

  // Otherwise, if it's not guarded or user is authorized, allow navigation
  next();
});



export default router
import { createStore } from 'vuex'

export default createStore({
  state: {
    time_out:5,
    is_idle:false,
    user:null,
    allGuardedRoutes: null,
    allPermissions: null,
    dohMlay: {
      showOrders: true,
      showNotes: false,
      showAddWH: false,
      showAvg: false,
      avgInput: 3,
      expInput: 3,
      checkSales: false,
      calcSales: false,
      calcSalesItems: [],
      showSalesRep: false,
      recentSalesRep: [],
      showRecentSalesRep: false,
      finishedSalesRep: false,
      showSalesRepIdx: null,
      exp3: false,
      noStock6: false,
      noStock: false,
      slowSelling: false,
      noOrders: false,
      urgentOnly: false,
      user_orders: [],
      showUserOrders: false,
      selectedUserOrder: null,
      show_orders_list: false,
      all_products: []
    },
    system:{
      platform: null,
      loading: false,
    },
    bi:{
      items: null,
      factories: null,
      suppliers: null,
      weeklyStock: null,
      c_merakzim: null,
      clients: null,
      assortments: null,
    },
    reports:{
      stock_report:null,
      last_update: null,
      sales_report: [],
      suppliers: [],
      factories: [],
      order_report: null
    },
    melaket:{
      saved_search: null,
      orders: []
    },
    locations:{
      paths: [],
      updated: null,
      pallets: [],
      last_update: null
    },
    selected_order: null
  }
})
